import { Grid, Typography } from '@mui/material'
import React, { useRef } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Carasoul from './Carasoul';
const WishlistSection = () => {
  const slickRef = useRef()
  const handleNext = () => {
    slickRef.current?.slickNext();
  }
  const handleBack = () => {
    slickRef.current.slickPrev();
  }
  return (
    <Grid container item sx={{ padding: { lg: '50px 200px', sm: '20px 100px', xs: '20px 50px' } }} xs={12} spacing={2}>
      <Grid item xs={12}>
        <Typography fontSize={{ lg: 40, xs: 30 }} fontWeight={700} display={'flex'} flexWrap={'wrap'}>
          Explore The&nbsp;<Typography fontSize={{ lg: 40, xs: 30 }} fontWeight={700} color='#FBAD18'>World&nbsp;</Typography>From Here
        </Typography>
        <Typography color='#777497'>
          Find flights departing
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Carasoul slickRef={slickRef} />
      </Grid>
      <Grid item container xs={12} justifyContent={'space-between'} alignItems={'center'} rowSpacing={2}>
        <Grid container item xs={6}>
          <ArrowBackIcon onClick={() => handleBack()} fontSize='large' sx={{ color: '#1E1A54', cursor: 'pointer', fontSize: '50px' }} />
          <ArrowForwardIcon onClick={() => handleNext()} fontSize='large' sx={{ marginLeft: '20px', color: '#FBAD18', cursor: 'pointer', fontSize: '50px' }} />
        </Grid>
        <Grid item >
          <Typography color='#1E1A54'>View all Organized Trips</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default WishlistSection