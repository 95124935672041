import { Avatar, Button, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import FormHandler from '../shared-components/FormHandler';
import * as yup from 'yup'
import { Link, useNavigate } from "react-router-dom"
import { useDispatch } from 'react-redux';
import { forgotPassword, loginUser, setToken } from '../../store/userSlice';
import { handleOpenSnackbar } from '../../store/homeSlice';
function ForgotPassword() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const initialValues = {
        email: '',
    }
    const validationSchema = yup.object({
        email: yup.string().email('Invalid email').required('Required'),
    })
    const handleForgotPassword = ({ values, actions }) => {
        const formData = new FormData()
        Object.entries(values).forEach(([key, value]) => {
            formData.append(key, value);
        });
        dispatch(forgotPassword(formData)).then((response) => {
            if (response?.error) {
                actions.setErrors({ email: "We couldn't find an account associated with that email" })
            } else {
                dispatch(handleOpenSnackbar({
                    open: true,
                    type: 'success',
                    message: 'Password Reset Link Sent Succesfully!'
                }))
                navigate('/login_page')
            }
        })
    }
    return (
        <FormHandler
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleForgotPassword}
            isEditMode={false}
            hideActionButton={true}
        >
            {({ formik }) => {
                return (
                    <Grid container sx={{ backgroundImage: `url('images/image 19.png')`, backgroundSize: 'cover', minHeight: '92.8dvh', paddingLeft: '10%', paddingTop: '10%' }}>
                        <Grid container spacing={2} lg={4} md={5} sm={8} xs={10} sx={{ backgroundColor: 'white', maxHeight: '450px', height: '400px', boxShadow: '0 0 10px 0 gray', color: 'black', padding: '20px 20px', borderRadius: '10px' }}>
                            <Grid item xs={12} container spacing={1}>
                                <Grid item xs={12} container justifyContent={'center'}>
                                    <Typography fontWeight={700} color='#1E1A54' fontSize={25}>Forget Password!</Typography>
                                </Grid>
                                <Grid item xs={12} justifyContent={'center'}>
                                    <Typography fontSize={16} sx={{ textAlign: 'center' }} color='#1E1A54'>Enter your email address and we'll send you an email with instructions to reset your password.</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    name='email'
                                    fullWidth
                                    label="Email"
                                    required
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: '#FBAD18',
                                            color: 'white',
                                        }, backgroundColor: '#1E1A54',
                                        textTransform: 'none'
                                    }}
                                    fullWidth
                                    onClick={() => formik.handleSubmit()}
                                >
                                    Reset password
                                </Button>
                            </Grid>
                            <Grid item xs={12} container justifyContent={'end'} padding={'20px'}>
                                <Typography>
                                    <Link to="/login_page" >Log In</Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }
            }
        </FormHandler>
    )
}

export default ForgotPassword;
