import React, { useRef } from 'react'
import { wishlistCarasoulData } from '../../constants/homeConstants'
import Slider from 'react-slick';
import { Avatar, Button, Card, CardActions, CardContent, CardMedia, Chip, Divider, Grid, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
const Carasoul = (props) => {
    const { slickRef } = props
    var settings = {
        accessibility: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },

        ]
    };
    return (
        <Grid className='m-auto'>
            <Grid>
                <Slider ref={slickRef} {...settings}>
                    {wishlistCarasoulData.map((item, index) => (
                        <Grid key={index} className='text-black rounded-2xl p-2'>
                            <Card sx={{ borderRadius: '20px' }} varient='outlined'>
                                <CardMedia
                                    component="img"
                                    alt="green iguana"
                                    sx={{ height: '300px' }}
                                    image={item?.image}
                                />
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item container>
                                            <Typography color='#1E1A54' fontSize={22} fontWeight={600} component="div">
                                                Grand Prismatic Spring
                                            </Typography>
                                        </Grid>
                                        <Grid item container>
                                            <Typography variant="body2" color="#777497">
                                                Lizards are a widespread group of squamate reptiles, with over 6,000
                                                species, ranging across all continents except Antarctica
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                        <Grid item container justifyContent={'space-between'} alignItems={'center'}>
                                            <Grid item display={'flex'} alignItems={'center'} gap={1}>
                                                <img alt="PTS" src="images/pts.png" />
                                                <Typography color="#1E1A54" fontSize={22}>1494</Typography>
                                            </Grid>
                                            <Grid item display={'flex'} alignItems={'center'} gap={1}>
                                                <img src='images/stars.png' />
                                                <Chip icon={<AccessTimeIcon />} sx={{ bgcolor: '#FEF4C5' }} label="10 Hours" />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Slider>
            </Grid>
        </Grid>
    )
}

export default Carasoul