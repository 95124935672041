export const wishlistCarasoulData = [
    {
        image: 'images/Rectangle.png',
        title: 'Lorem, Epsum'
    },
    {
        image: 'images/image2.jpg',
        title: 'Lorem, Epsum'
    },
    {
        image: 'images/image3.jpg',
        title: 'Lorem, Epsum'
    },
    {
        image: 'images/Rectangle.png',
        title: 'Lorem, Epsum'
    },
    {
        image: 'images/image1.jpg',
        title: 'Lorem, Epsum'
    },
    {
        image: 'images/Rectangle.png',
        title: 'Lorem, Epsum'
    },
    {
        image: 'images/image3.jpg',
        title: 'Lorem, Epsum'
    },
]

export const section5CarasoulData = [
    {
        image: 'section5/user.png',
        text: '“On the Windows talking painted pasture yet its express parties use. Sure last upon he same as knew next. Of believed or diverted no.”',
        name: 'Mike taylor',
        location: 'Lahore, Pakistan'
    },
    {
        image: 'section5/user.png',
        text: '“On the Windows talking painted pasture yet its express parties use. Sure last upon he same as knew next. Of believed or diverted no.”',
        name: 'Mike taylor',
        location: 'Lahore, Pakistan'
    },
    {
        image: 'section5/user.png',
        text: '“On the Windows talking painted pasture yet its express parties use. Sure last upon he same as knew next. Of believed or diverted no.”',
        name: 'Mike taylor',
        location: 'Lahore, Pakistan'
    },
    {
        image: 'section5/user.png',
        text: '“On the Windows talking painted pasture yet its express parties use. Sure last upon he same as knew next. Of believed or diverted no.”',
        name: 'Mike taylor',
        location: 'Lahore, Pakistan'
    },
]


export const tripMode = [
    {
        label: 'One Way',
        value: 'One_Way'
    },
    {
        label: 'Round Trip',
        value: 'Round_Trip'
    }
]

export const noOfPassengers = [
    {
        label: '1 Passenger',
        value: 1
    },
    {
        label: '2 Passengers',
        value: 2
    },
    {
        label: '3 Passengers',
        value: 3
    },
    {
        label: '4 Passengers',
        value: 4
    },
    {
        label: '5 Passengers',
        value: 5
    },
    {
        label: '6 Passengers',
        value: 6
    },
    {
        label: '7 Passengers',
        value: 7
    },
    {
        label: '8 Passengers',
        value: 8
    },
    {
        label: '9 Passengers',
        value: 9
    },
]

export const classes = [
    {
        label: 'Economy',
        value: 'economy'
    },
    {
        label: 'Buisness',
        value: 'buisness'
    },
    {
        label: 'First',
        value: 'first'
    },

]