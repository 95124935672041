import React, { useRef } from 'react'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Card, CardContent, Divider, Grid, Typography, Avatar } from '@mui/material';
import { section5CarasoulData } from '../../constants/homeConstants';
import Slider from 'react-slick';

var settings = {
    accessibility: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
            }
        },
        {
            breakpoint: 900,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
            }
        },

    ]
};
const Section5 = () => {
    const slickRef = useRef()
    const handleNext = () => {
        slickRef.current?.slickNext();
    }
    const handleBack = () => {
        slickRef.current?.slickPrev();
    }
    return (
        <Grid container justifyContent={'space-between'} item sx={{ padding: { lg: '100px 0 100px 200px', sm: '50px', xs: '50px' } }} xs={12} spacing={2}>
            <Grid item container xs={12} sm={12} lg={3} alignItems={'space-between'}>
                <Grid item container>
                    <Grid item xs={12}>
                        <Typography color='#777497'>Testimonials</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{ lineHeight: '42px' }} color='#302842' fontSize={40} fontWeight={600}>What People Say About Us</Typography>
                    </Grid>
                </Grid>
                <Grid item container alignItems={'end'}>
                    <KeyboardArrowLeftIcon onClick={handleBack} fontSize='large' sx={{ color: '#1E1A54', cursor: 'pointer', fontSize: '50px' }} />
                    <KeyboardArrowRightIcon onClick={handleNext} fontSize='large' sx={{ marginLeft: '20px', color: '#1E1A54', cursor: 'pointer', fontSize: '50px' }} />
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} lg={9}>
                <Slider ref={slickRef} {...settings}>
                    {section5CarasoulData.map((item, index) =>
                        <Grid key={index} className='text-black px-2'>
                            <Card className='p-4' sx={{ borderRadius: '20px', border: '1px solid #DEE6FF' }} varient='outlined'>
                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid item container justifyContent={'space-between'}>
                                            <Grid item xs={12} lg={2}>
                                                <Avatar alt="Remy Sharp" src={item?.image} sx={{ width: 85, height: 85 }} />
                                            </Grid>
                                            <Grid item xs={12} lg={10}>
                                                <Typography fontSize={18} fontWeight={500} color='#777497'>{item?.text}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography fontWeight={600} fontSize={20} color='#1E1A54' sx={{ marginLeft: '100px' }}>{item?.name}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography fontWeight={500} fontSize={16} color='#FBAD18' sx={{ marginLeft: '100px' }}>{item?.location}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                </Slider>
            </Grid >
        </Grid >
    )
}

export default Section5