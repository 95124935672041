import { Grid } from '@mui/material'
import React from 'react'

const Section6 = () => {
    return (
        <Grid container justifyContent={'space-between'} alignItems={'center'} item sx={{ padding: { lg: '50px 30px', sm: '50px 15px', xs: '50px 5px' } }} xs={12} spacing={2}>
            <Grid item>
                <img src='section6/axon.png' />
            </Grid>
            <Grid item>
                <img src='section6/jetstar.png' />
            </Grid>
            <Grid item>
                <img src='section6/exepedia.png' />
            </Grid>
            <Grid item>
                <img src='section6/litalia.png' />
            </Grid>
            <Grid item>
                <img src='section6/quantas.png' />
            </Grid>
        </Grid>
    )
}

export default Section6