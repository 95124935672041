import { Grid } from '@mui/material';
import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { AppBar, Box, Toolbar, IconButton, Typography, Menu, Container, Avatar, Button, Tooltip, MenuItem } from '@mui/material';
import AdbIcon from '@mui/icons-material/Adb';
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { setToken } from '../../store/userSlice';
import { pages, settings, logout } from '../constants/navbarConstants';

function Navbar() {
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { token, username } = useSelector(state => state.user)
    const handleNavigate = (page) => {
        if (page == 'Login') {
            navigate('/login_page')
            handleCloseNavMenu()
        } else if (page == 'Register') {
            navigate('/sign_up')
            handleCloseNavMenu()
        } else {
            navigate('/')
            handleCloseNavMenu()
        }
    }
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = (setting) => {
        setAnchorElUser(null);
        if (setting == 'Logout') {
            dispatch(setToken(null))
            localStorage.removeItem('Token')
            navigate('/login_page')
        }
    };
    return (
        <Grid>
            <AppBar position="static" sx={{ backgroundColor: '#FFFFFF' }}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Grid xs={12} container alignItems={'center'}>
                            <Grid item alignItems={'center'} container xs={6} md={4}>
                                <Avatar maxWidth sx={{ display: { xs: 'none', md: 'flex' }, height: '100%', width: '200px', borderRadius: 0 }} alt="Logo" src={'images/Logo.png'} />
                                <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                                    {token && <><IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleOpenNavMenu}
                                        color="black"
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                        <Menu
                                            id="menu-appbar"
                                            anchorEl={anchorElNav}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                            open={Boolean(anchorElNav)}
                                            onClose={handleCloseNavMenu}
                                            sx={{
                                                display: { xs: 'block', md: 'none' },
                                            }}
                                        >
                                            {pages.map((page) => (
                                                <MenuItem key={page} onClick={handleCloseNavMenu}>
                                                    <Typography textAlign="center">{page}</Typography>
                                                </MenuItem>
                                            ))}
                                        </Menu></>}
                                    {!token && <><IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleOpenNavMenu}
                                        color="black"
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                        <Menu
                                            id="menu-appbar"
                                            anchorEl={anchorElNav}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                            open={Boolean(anchorElNav)}
                                            onClose={handleCloseNavMenu}
                                            sx={{
                                                display: { xs: 'block', md: 'none' },
                                            }}
                                        >
                                            {logout.map((page) => (
                                                <MenuItem key={page} onClick={() => handleNavigate(page)}>
                                                    <Typography textAlign="center">{page}</Typography>
                                                </MenuItem>
                                            ))}
                                        </Menu></>}
                                </Box>
                                <Avatar maxWidth sx={{ display: { xs: 'flex', md: 'none' }, height: '100%', width: '150px', borderRadius: 0 }} alt="Logo" src={'images/Logo.png'} />
                            </Grid>
                            {token && <Grid xs={1} sm={1} md={4} item container >
                                <Box sx={{ flexGrow: 1, justifyContent: 'space-between', display: { xs: 'none', md: 'flex' } }}>
                                    {pages.map((page) => (
                                        <Button
                                            key={page}
                                            onClick={() => console.log('hello')}
                                            sx={{ my: 2, color: 'white', display: 'block', color: '#828282', textTransform: 'none' }}
                                        >
                                            {page}
                                        </Button>
                                    ))}
                                </Box>
                            </Grid>}
                            {token && <Grid xs={5} sm={4} md={4} item container justifyContent='end'>
                                <Box sx={{ flexGrow: 0, display: 'flex' }}>
                                    <Tooltip title="Open settings">
                                        <Grid container alignItems={'center'}>
                                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                                            </IconButton>
                                            <Typography fontSize={{ xs: 0, md: 16 }} sx={{ paddingLeft: '5px' }} display={'flex'} color='#1E1A54'>Hello, <Typography display={'flex'} color='#1E1A54' fontWeight={'bold'}>{username}</Typography></Typography>
                                        </Grid>
                                    </Tooltip>
                                    <Menu
                                        sx={{ mt: '45px' }}
                                        id="menu-appbar"
                                        anchorEl={anchorElUser}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorElUser)}
                                        onClose={handleCloseUserMenu}
                                    >
                                        {settings.map((setting) => (
                                            <MenuItem key={setting} onClick={() => handleCloseUserMenu(setting)}>
                                                <Typography textAlign="center">{setting}</Typography>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Box>
                            </Grid>}
                            {!token && <Grid md={8} item container justifyContent={'end'}>
                                <Box sx={{ flexGrow: 1, justifyContent: 'end', columnGap: '10px', display: { xs: 'none', md: 'flex' } }}>
                                    {logout.map((page) => (
                                        <Button
                                            key={page}
                                            onClick={() => handleNavigate(page)}
                                            sx={{
                                                '&:hover': {
                                                    color: 'gray'
                                                }, my: 2, display: 'block', textTransform: 'none', bgcolor: page == 'Signup' && '#FBAD18', color: page == 'Signup' ? 'white' : 'gray !important'
                                            }}
                                        >
                                            {page}
                                        </Button>
                                    ))}
                                </Box>
                            </Grid>}
                        </Grid>
                    </Toolbar>
                </Container>
            </AppBar>
        </Grid>
    )
}

export default Navbar;
