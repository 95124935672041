import { Avatar, Grid, Typography } from '@mui/material'
import React from 'react'

const Section2 = () => {
    return (
        <Grid container justifyContent={'space-between'} item sx={{ padding: { lg: '120px 200px', sm: '60px 100px', xs: '50px 50px' } }} xs={12} spacing={2}>
            <Grid item container spacing={4} xs={12} md={6} lg={4}>
                <Grid item>
                    <Avatar maxWidth sx={{ width: '50px', height: '50px', padding: '15px', bgcolor: '#FFF5C6', borderRadius: 4 }} alt="Logo" src={'section2/Support.svg'} />
                </Grid>
                <Grid item xs={10}>
                    <Typography fontSize={22} fontWeight={600} color='#302842'>Support</Typography>
                    <Typography color='#777497'>Condimentum lobortis donec nibh molestie massa dictumst cursus.</Typography>
                </Grid>
            </Grid>
            <Grid item container spacing={4} xs={12} md={6} lg={4}>
                <Grid item>
                    <Avatar maxWidth sx={{ objectFit: 'unset', width: '50px', height: '50px', padding: '15px', bgcolor: '#FFF5C6', borderRadius: 4 }} alt="Logo" src={'section2/Security.svg'} />
                </Grid>
                <Grid item xs={10}>
                    <Typography fontSize={22} fontWeight={600} color='#302842'>Security</Typography>
                    <Typography color='#777497'>Condimentum lobortis donec nibh molestie massa dictumst cursus.</Typography>
                </Grid>
            </Grid>
            <Grid item container spacing={4} xs={12} md={6} lg={4}>
                <Grid item>
                    <Avatar maxWidth sx={{ width: '50px', height: '50px', padding: '15px', bgcolor: '#FFF5C6', borderRadius: 4 }} alt="Logo" src={'section2/Quality.svg'} />
                </Grid>
                <Grid item xs={10}>
                    <Typography fontSize={22} fontWeight={600} color='#302842'>Quality</Typography>
                    <Typography color='#777497'>Condimentum lobortis donec nibh molestie massa dictumst cursus.</Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Section2