// Footer.js

import React from 'react';
import { Avatar, Divider, Grid, Typography } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {
    return (
        <Grid container sx={{ width: '100%', bottom: 0, backgroundColor: '#1E1A54', color: '#fff', padding: { lg: '50px 200px', sm: '20px 100px', xs: '20px 50px' } }}>
            <Grid container item xs={12} spacing={3}>
                <Grid item container xs={12} alignItems={'center'}>
                    <Grid item container xs={12} sm={7} lg={4}>
                        <Avatar maxWidth sx={{ display: { md: 'flex' }, height: '100%', width: '200px', borderRadius: 0 }} alt="Logo" src={'images/Dark-Logo.svg'} />
                    </Grid>
                    <Grid item container display={{ sm: 'none', xs: 'none', md: 'flex', lg: 'flex' }} xs={12} sm={12} lg={8}>
                        <Grid item xs={6}>
                            <Typography display={'flex'} color="gray">Our services & pricing</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography display={'flex'} color="gray">Popular Destinations</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container xs={12} alignItems={'center'} rowSpacing={3}>
                    <Grid item container xs={12} sm={12} lg={4}>
                        <Typography color="gray">Follow Us</Typography>
                        <Grid container xs={12} spacing={2} sx={{ marginTop: '5px' }}>
                            <Grid item >
                                <FacebookIcon />
                            </Grid>
                            <Grid item >
                                <TwitterIcon />
                            </Grid>
                            <Grid item>
                                <LinkedInIcon />
                            </Grid>
                            <Grid item>
                                <InstagramIcon />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container display={{ sm: 'none', xs: 'none', md: 'grid', lg: 'grid' }} xs={12} sm={12} lg={8} spacing={2}>
                        <Grid xs={12} container item>
                            <Grid item xs={3}>
                                <Typography display={'flex'} >Hekos</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography display={'flex'} >Earn more points</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography display={'flex'} >Emirates, United Arabian</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography display={'flex'} >Emirates, United Arabian</Typography>
                            </Grid>
                        </Grid>
                        <Grid xs={12} container item>
                            <Grid item xs={3}>
                                <Typography display={'flex'} >Flights</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography display={'flex'} >About</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography display={'flex'} >New York City, USA</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography display={'flex'} >New York City, USA</Typography>
                            </Grid>
                        </Grid>
                        <Grid xs={12} container item>
                            <Grid item xs={3}>
                                <Typography display={'flex'} >Help</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography display={'flex'} >Teams of use</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography display={'flex'} >One Bridge, Belgium</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography display={'flex'} >One Bridge, Belgium</Typography>
                            </Grid>
                        </Grid>
                        <Grid xs={12} container item>
                            <Grid item xs={3}>
                                <Typography display={'flex'} >Blogs</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography display={'flex'} >Privacy policy</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography display={'flex'} >Golden Frame, Dubai</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography display={'flex'} >Golden Frame, Dubai</Typography>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Divider color='gray' />
                </Grid>
                <Grid item container xs={6} justifyContent={'start'}>
                    <Typography display={'flex'} color='gray'>Copyright © 2024<Typography margin={'0 5px'} color='#FBAD18'>Awardtiger</Typography> All rights reserved</Typography>
                </Grid>
                <Grid item xs={6} container justifyContent={'end'}>
                    <Typography color='gray'>Version 0.1</Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Footer;
