import React, { useRef, useState } from 'react'
import Card from '@mui/material/Card';
import { Autocomplete, Box, Button, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, Grid, Menu, MenuItem, Paper, Popper, TextField, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { classes, noOfPassengers, tripMode } from '../../constants/homeConstants';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import SearchIcon from '@mui/icons-material/Search';
import { get } from 'lodash'
import { useDispatch } from 'react-redux';
import { handleOpenSnackbar, setDestinationAirportData, setSourceAirportData } from '../../../store/homeSlice';
import { createFilterOptions } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { makeStyles } from '@mui/styles'
import { BorderBottom } from '@mui/icons-material';

const useStyles = makeStyles({
    datePicker: {
        '& .MuiOutlinedInput-root': {
            border: 'none', // Remove border from the input
            padding: 0
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none !important', // Remove border outline
        },
        '& .MuiOutlinedInput-input': {
            padding: 0, // Remove padding from the input
        },
        // '& .MuiOutlinedInput-input:first-child': {
        //     BorderBottom: '0 !important', // Remove border from the input
        // },
        // '& .MuiOutlinedInput-input:last-child': {
        //     BorderBottom: '0 !important', // Remove border from the input
        // },

    },
    autocomplete: {
        '& .MuiInput-root': {
            border: 'none !important', // Remove border from the input
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
            borderBottom: 'none',
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:last-child': {
            borderBottom: 'none',
        },
    },
    input: {
        border: 'none !important',
    }
});

const FlightsCheckSection = (props) => {
    const { formik, state } = props;
    const [tripModeAnchorEl, setTripModeAnchorEl] = useState(null);
    const [passengerAnchorEl, setPassengerAnchorEl] = useState(null);
    const [classAnchorEl, setClassAnchorEl] = useState(null);
    const muiClasses = useStyles();
    const dispatch = useDispatch()
    const handleTripModeClick = (event) => {
        setTripModeAnchorEl(event.currentTarget);
    };

    const handlePassengerClick = (event) => {
        setPassengerAnchorEl(event.currentTarget);
    };

    const handleClassClick = (event) => {
        setClassAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setTripModeAnchorEl(null);
        setPassengerAnchorEl(null);
        setClassAnchorEl(null)
    };

    const handleSelectTripMode = (value) => {
        formik.setFieldValue('trip', value);
        handleClose();
    };

    const handleSelectPassenger = (value) => {
        formik.setFieldValue('number_of_passengers', value);
        handleClose();
    };

    const handleSelectClass = (value) => {
        formik.setFieldValue('class', value);
        handleClose();
    }

    const handleToggleCountry = () => {
        const sourceValue = formik.values.source
        formik.setFieldValue('source', formik.values.destination)
        dispatch(setDestinationAirportData(formik.values.destination))
        formik.setFieldValue('destination', sourceValue)
        dispatch(setSourceAirportData(sourceValue))
    }
    const defaultFilterOptions = createFilterOptions();

    const filterOptions = (options, state) => {
        return defaultFilterOptions(options, state).slice(0, 4);
    };
    return (
        <Card sx={{ borderRadius: '100px', boxShadow: '10px 30px 70px 5px #00000012', width: '100%' }}>
            <Grid container spacing={2} className='px-10 py-5'>
                <Grid item container xs={12} spacing={2} alignItems={'center'}>
                    <Grid item sx={{ paddingLeft: '10px !important' }}>
                        <Button
                            endIcon={<KeyboardArrowDownIcon />}
                            sx={{
                                '&:hover': {
                                    backgroundColor: '#1E1A54',
                                    color: 'white',
                                },
                                backgroundColor: '#1E1A54',
                                color: 'white',
                                textTransform: 'none',
                                borderRadius: '40px'
                            }}
                            aria-controls={tripModeAnchorEl ? 'trip-mode-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={tripModeAnchorEl ? 'true' : undefined}
                            onClick={handleTripModeClick}
                        >
                            {formik.values.trip?.label}
                        </Button>
                        <Menu
                            id="trip-mode-menu"
                            anchorEl={tripModeAnchorEl}
                            open={Boolean(tripModeAnchorEl)}
                            onClose={handleClose}
                        >
                            {tripMode.map((item) => (
                                <MenuItem key={item.value} onClick={() => handleSelectTripMode(item)}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Grid>
                    <Grid item>
                        <Button
                            endIcon={<KeyboardArrowDownIcon />}
                            sx={{
                                '&:hover': {
                                    backgroundColor: '#1E1A54',
                                    color: 'white',
                                },
                                backgroundColor: '#1E1A54',
                                color: 'white',
                                textTransform: 'none',
                                borderRadius: '40px'
                            }}
                            aria-controls={passengerAnchorEl ? 'passenger-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={passengerAnchorEl ? 'true' : undefined}
                            onClick={handlePassengerClick}
                        >
                            {`${formik.values.number_of_passengers} Passenger${formik.values.number_of_passengers !== 1 ? 's' : ''}`}
                        </Button>
                        <Menu
                            id="passenger-menu"
                            anchorEl={passengerAnchorEl}
                            open={Boolean(passengerAnchorEl)}
                            onClose={handleClose}
                        >
                            {noOfPassengers.map((item) => (
                                <MenuItem key={item.value} onClick={() => handleSelectPassenger(item.value)}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Grid>
                    <Grid item>
                        <Button
                            endIcon={<KeyboardArrowDownIcon />}
                            sx={{
                                '&:hover': {
                                    backgroundColor: '#1E1A54',
                                    color: 'white',
                                },
                                backgroundColor: '#1E1A54',
                                color: 'white',
                                textTransform: 'none',
                                borderRadius: '40px'
                            }}
                            aria-controls={classAnchorEl ? 'class-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={classAnchorEl ? 'true' : undefined}
                            onClick={handleClassClick}
                        >
                            {formik.values.class?.label}
                        </Button>
                        <Menu
                            id="class-menu"
                            anchorEl={classAnchorEl}
                            open={Boolean(classAnchorEl)}
                            onClose={handleClose}
                        >
                            {classes.map((item) => (
                                <MenuItem key={item.value} onClick={() => handleSelectClass(item)}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Grid>
                    <Grid item>
                        <FormControl component="fieldset">
                            <FormControlLabel
                                control={<Checkbox name="flexibleDates" value={formik.values.flexibleDates} checked={formik.values.flexibleDates} onChange={formik.handleChange} />}
                                label={'Flexible Dates'}
                                sx={{ color: '#1E1A54' }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item container xs={12} alignItems={'center'} spacing={2} justifyContent={'space-between'} className='px-4'>
                    <Grid item container xs={11} rowSpacing={2} alignItems={'start'} justifyContent={'space-between'}>
                        <Grid item sx={{ cursor: 'pointer' }} xs={12} lg={3}>
                            <Typography color='#302842' fontWeight={500}>Origin</Typography>
                            <Autocomplete
                                className='autocomplete'
                                key={formik.values.source}
                                freeSolo
                                filterOptions={filterOptions}
                                value={formik.values.source}
                                options={get(state, 'sourceAirportData', [])}
                                getOptionLabel={(option) => `${option?.name}, ${option?.city}, ${option.country}, ${option.code}`}
                                onChange={(_, newValue) => {
                                    dispatch(setDestinationAirportData(newValue))
                                    formik.setFieldValue('source', newValue)
                                }}
                                onInputChange={(event, newValue) => {
                                    formik.setFieldValue('searchSource', newValue)
                                }}
                                onBlur={() => formik.setFieldTouched('source', true)}
                                renderOption={(props, option, { selected }) => (
                                    <Box
                                        component='li'
                                        sx={{
                                            '& > img': { mr: 3, flexShrink: 0 },
                                            '&.MuiAutocomplete-option[aria-selected="true"]': {
                                                backgroundColor: 'transparent',
                                                width: '100%',
                                            },
                                        }}
                                        {...props}
                                    >
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography fontWeight={'bold'} fontSize={18}>{get(option, 'name')}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography fontSize={14} color='gray'>{`${get(option, 'city')}, ${get(option, 'state')}, ${get(option, 'country')}`}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        name='source'
                                        placeholder='Enter your origin'
                                    />
                                )}
                                fullWidth
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={handleToggleCountry}
                                sx={{
                                    color: 'black',
                                    textTransform: 'none',
                                }}><SyncAltIcon /></Button>
                        </Grid>
                        <Grid item sx={{ cursor: 'pointer' }} xs={12} lg={3}>
                            <Typography color='#302842' fontWeight={500}>Destination</Typography>
                            <Autocomplete
                                className='autocomplete'
                                key={formik.values.destination}
                                freeSolo
                                filterOptions={filterOptions}
                                value={formik.values.destination}
                                options={get(state, 'destinationAirportData', [])}
                                getOptionLabel={(option) => `${option?.name}, ${option?.city}, ${option?.country}, ${option.code}`}
                                onChange={(_, newValue) => {
                                    formik.setFieldValue('destination', newValue)
                                    dispatch(setSourceAirportData(newValue))
                                }}
                                onInputChange={(event, newValue) => {
                                    formik.setFieldValue('searchDestination', newValue)
                                }}
                                onBlur={() => formik.setFieldTouched('destination', true)}
                                PopperComponent={(props) => (
                                    <Popper {...props} style={{ zIndex: 99999 }}>
                                        <Paper {...props} />
                                    </Popper>
                                )}
                                renderOption={(props, option, { selected }) => (
                                    <Box
                                        component='li'
                                        sx={{
                                            '& > img': { mr: 3, flexShrink: 0 },
                                            '&.MuiAutocomplete-option[aria-selected="true"]': {
                                                backgroundColor: 'transparent',
                                                width: '100%',
                                            },
                                        }}
                                        {...props}
                                    >
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography fontWeight={'bold'} fontSize={18}>{get(option, 'name')}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography fontSize={14} color='gray'>{`${get(option, 'city')}, ${get(option, 'state')}, ${get(option, 'country')}`}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name='destination'
                                        variant='standard'
                                        placeholder='Enter your destination'
                                    />
                                )}
                                fullWidth
                            />
                        </Grid>
                        <Divider orientation="vertical" variant="middle" flexItem />
                        <Grid item sx={{ cursor: 'pointer' }} xs={12} lg={2}>
                            <Typography color='#302842' fontWeight={500}>Departure</Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker sx={{ width: '155px' }} className={muiClasses.datePicker} onChange={(newValue) => formik.setFieldValue('departure_date', newValue)} value={formik.values.departure_date} />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid>
                        <Grid item sx={{ cursor: 'pointer' }} xs={12} lg={2}>
                            <Typography color='#302842' fontWeight={500}>Return</Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer className='date-input' components={['DatePicker']}>
                                    <DatePicker disabled={formik.values.trip?.value != 'Round_Trip'} sx={{ width: '155px' }} className={muiClasses.datePicker} onChange={(newValue) => formik.setFieldValue('arrival_date', newValue)} value={formik.values.arrival_date} />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                    <Grid item container xs={1} justifyContent={'end'} sx={{ paddingTop: '0px !important' }}>
                        {get(state, 'loading.milezLoading') ? <CircularProgress /> : <Button
                            onClick={() => formik.handleSubmit()}
                            disabled={Object.keys(formik.errors).length > 0}
                            sx={{
                                '&:hover': {
                                    backgroundColor: '#FBAD18',
                                    color: 'white',
                                },
                                '&:disabled': {
                                    backgroundColor: 'gray',
                                    color: 'black'
                                },
                                padding: '15px 0px',
                                backgroundColor: '#1E1A54',
                                color: 'white',
                                textTransform: 'none',
                                borderRadius: '100%'
                            }}><SearchIcon /></Button>}
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    )
}

export default FlightsCheckSection