import { Avatar, Button, Grid, Typography } from '@mui/material'
import React from 'react'
import { get } from 'lodash'
import moment from 'moment'

const SubscribeDialog = (props) => {
    const { data, handleSubscribeClick, state } = props
    const { milezData } = state
    return (
        <Grid container className='pl-10 pr-2' xs={12} spacing={4}>
            <Grid item xs={12} container justifyContent={'center'}>
                <Typography color='#FF0000' fontSize={20}>Good News!&nbsp;</Typography><Typography fontSize={20} color='#302842'> We Found The Following Route Information</Typography>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={12} className='text-center'>
                    <Typography color='#170F49' fontWeight={600} fontSize={34}>{get(data, 'source.city', '')} {get(data, 'source.country', '')}, WA to {get(data, 'destination.city', '')} {get(data, 'destination.country', '')}</Typography>
                </Grid>
                <Grid item xs={12} className='text-center'>
                    <Typography color='#828282' fontWeight={600} fontSize={22}>({moment(get(data, 'departure_date', '')).format('MMMM Do')}{get(data, 'arrival_date') && ` - ${moment(get(data, 'arrival_date', '')).format('MMMM Do')}`})</Typography>
                </Grid>
            </Grid>
            <Grid container item xs={12} gap={3}>
                <Grid item xs={12}>
                    <Typography fontWeight={400} fontSize={18}>Average Miles:</Typography>
                </Grid>
                <Grid item xs={12} >
                    <Grid item container xs={12} border={'1px solid gray'} className='pb-3 pr-3' borderRadius={3} spacing={2}>
                        {get(milezData, 'result.united', false) && <Grid item container xs={12} justifyContent={'space-between'} alignItems={'center'}>
                            <Grid item container xs={6} spacing={1} alignItems={'center'}>
                                <Grid item>
                                    <Avatar src='logo/United_logo.png' variant="square" />
                                </Grid>
                                <Grid item>
                                    <Typography fontWeight={500} fontSize={20}>United</Typography>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Typography fontWeight={400} fontSize={20}>{get(milezData, 'result.united', '')} Miles/Person</Typography>
                            </Grid>
                        </Grid>}
                        {get(milezData, 'result.american', false) && <Grid item container xs={12} justifyContent={'space-between'} alignItems={'center'}>
                            <Grid item container xs={6} spacing={1} alignItems={'center'}>
                                <Grid item>
                                    <Avatar src='logo/American_logo.png' variant="square" />
                                </Grid>
                                <Grid item>
                                    <Typography fontWeight={500} fontSize={20}>American</Typography>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Typography fontWeight={400} fontSize={20}>{get(milezData, 'result.american', '')} Miles/Person</Typography>
                            </Grid>
                        </Grid>}
                        {get(milezData, 'result.delta', false) && <Grid item container xs={12} justifyContent={'space-between'} alignItems={'center'}>
                            <Grid item container xs={6} spacing={1} alignItems={'center'}>
                                <Grid item>
                                    <Avatar src='logo/Delta_logo.png' variant="square" />
                                </Grid>
                                <Grid item>
                                    <Typography fontWeight={500} fontSize={20}>Delta</Typography>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Typography fontWeight={400} fontSize={20}>{get(milezData, 'result.delta', '')} Miles/Person</Typography>
                            </Grid>
                        </Grid>}
                        {get(milezData, 'result.alaska', false) && <Grid item container xs={12} justifyContent={'space-between'} alignItems={'center'}>
                            <Grid item container xs={6} spacing={1} alignItems={'center'}>
                                <Grid item>
                                    <Avatar src='logo/Delta_logo.png' variant="square" />
                                </Grid>
                                <Grid item>
                                    <Typography fontWeight={500} fontSize={20}>Alaska</Typography>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Typography fontWeight={400} fontSize={20}>{get(milezData, 'result.alaska', '')} Miles/Person</Typography>
                            </Grid>
                        </Grid>}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item spacing={2}>
                <Grid item container xs={12} justifyContent={'center'}>
                    <Typography fontSize={18} fontWeight={500}>Setup a Award Travel search for a better deal</Typography>
                </Grid>
                <Grid item container xs={12} justifyContent={'center'}>
                    <Button
                        onClick={handleSubscribeClick}
                        sx={{
                            paddingLeft: '15px',
                            paddingRight: '15px',
                            borderRadius: '20px',
                            fontSize: 18,
                            '&:hover': {
                                backgroundColor: '#FBAD18',
                                color: 'white',
                            },
                            '&:disabled': {
                                backgroundColor: 'gray',
                                color: 'black'
                            },
                            backgroundColor: '#1E1A54',
                            color: 'white',
                            textTransform: 'none',
                        }}>Subscribe</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SubscribeDialog