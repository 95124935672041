import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APIRequest } from "../utils/APIRequest";
import { apiRoutes } from "../main/constants/apiRoutes";

export const loginUser = createAsyncThunk('user/loginUser', async (data) => await APIRequest.post(apiRoutes.login, data))

export const signupUser = createAsyncThunk('user/signupUser', async (data) => await APIRequest.post(apiRoutes.signup, data))

export const forgotPassword = createAsyncThunk('user/forgotPassword', async (data) => await APIRequest.post(apiRoutes.forgotPassword, data))

export const getUser = createAsyncThunk('user/getUser', async () => await APIRequest.get(apiRoutes.getUser))

export const resetPassword = createAsyncThunk('user/resetPassword', async (data) => await APIRequest.post(apiRoutes.resetPassword, data))

const initialState = {
    token: null,
    username: '',
    loading: {
    }
}
const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUser.fulfilled, (state, { payload }) => {
                state.username = payload
            })
    }
})
export const { setToken } = userSlice.actions
export default userSlice.reducer