import React from 'react'
import { Grid, Typography } from '@mui/material'
import WishlistSection from './shared-components/WishlistSection'
import SearchFlightForm from './shared-components/SearchFlightForm'
import { useSelector } from 'react-redux'
import Footer from '../shared-components/Footer'
import Section2 from './shared-components/Section2'
import Section4 from './shared-components/Section4'
import Section5 from './shared-components/Section5'
import Section6 from './shared-components/Section6'

const Home = () => {
  const state = useSelector((state) => state.home)
  const { token } = useSelector(state => state.user)
  return (
    <>
      <Grid container justifyContent={'center'} alignItems={'center'} className='w-full'>
        <Grid container justifyContent={'center'} alignItems={'center'} sx={{ backgroundImage: `url('images/Banner.jpg')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', width: { lg: '90vw', md: '100vw' }, height: '100vh', margin: '10px' }}>
          <Grid item xs={11} lg={8}>
            <SearchFlightForm state={state} token={token} />
          </Grid>
        </Grid>
        <Grid container item sx={{ backgroundColor: '#F1F1FA' }}>
          <Section2 />
        </Grid>
        <Grid container item>
          <WishlistSection />
        </Grid>
        <Grid container item sx={{ backgroundColor: '#FBAD18' }}>
          <Section4 />
        </Grid>
        <Grid item xs={12}>
          <Section5 />
        </Grid>
        <Grid container item sx={{ backgroundColor: '#F1F1FA' }}>
          <Section6 />
        </Grid>
      </Grid>
      <Footer />
    </>
  )
}

export default Home