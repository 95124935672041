import { Avatar, Button, Grid, MenuItem, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import FormHandler from '../shared-components/FormHandler';
import { Link, useNavigate } from "react-router-dom"
import * as yup from 'yup'
import { checkPasswordUniqueness } from '../constants/navbarConstants';
import { setToken, signupUser } from '../../store/userSlice';
import { useDispatch } from 'react-redux';
import { handleOpenSnackbar } from '../../store/homeSlice';
function Sign_upPage() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const initialValues = {
        first_name: '',
        last_name: '',
        email: '',
        gender: '',
        password: '',
        confirmPassword: '',
    }
    const signupValidationSchema = yup.object({
        first_name: yup.string().required('Required'),
        last_name: yup.string().required('Required'),
        email: yup.string().email('Invalid email').required('Required'),
        gender: yup.string().required('Required'),
        password: yup.string()
            .required('Required')
            .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                'Password must be strong'
            )
            .test('unique-password', 'Password must be unique', async function (value) {
                const isUnique = await checkPasswordUniqueness(value);
                return isUnique;
            }),
        confirmPassword: yup.string()
            .oneOf([yup.ref('password'), null], 'Passwords must match')
            .required('Required'),
    })
    const handleSignup = ({ values, actions }) => {
        const formData = new FormData()
        Object.entries(values).forEach(([key, value]) => {
            formData.append(key, value);
        });
        dispatch(signupUser(formData)).then((response) => {
            if (response?.error) {
                actions.setErrors({ email: 'User with this email already exists' })
            } else {
                localStorage.setItem('Token', response.payload?.token)
                dispatch(setToken(response.payload?.token))
                dispatch(handleOpenSnackbar({
                    open: true,
                    type: 'success',
                    message: 'You can now Log In!'
                }))
                navigate('/login_page')
            }
        })
    }
    return (
        <FormHandler
            initialValues={initialValues}
            validationSchema={signupValidationSchema}
            onSubmit={handleSignup}
            isEditMode={false}
            hideActionButton={true}
        >
            {({ formik }) => {
                return (<Grid container sx={{ backgroundImage: `url('images/image 19.png')`, backgroundSize: 'cover', minHeight: '92.8dvh', paddingLeft: '10%', paddingTop: '10%' }}>
                    <Grid container spacing={2} lg={4} md={5} sm={8} xs={10} sx={{ backgroundColor: 'white', maxHeight: '550px', boxShadow: '0 0 10px 0 gray', color: 'black', padding: '20px 20px', borderRadius: '10px' }}>
                        <Grid item xs={12} container spacing={1}>
                            <Grid item xs={12} container justifyContent={'center'}>
                                <Typography fontWeight={700} color='#1E1A54' fontSize={25}>Create Your Account!</Typography>
                            </Grid>
                            <Grid item xs={12} justifyContent={'center'}>
                                <Typography fontSize={16} sx={{ textAlign: 'center' }} color='#1E1A54'>Sign Up Now To Unlock A World Of Seamless Travel Experiences</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    type="text"
                                    label="First Name"
                                    variant="outlined"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.first_name}
                                    name="first_name"
                                    helperText={formik.touched.first_name && formik.errors.first_name}
                                    error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    type="text"
                                    label="Last Name"
                                    variant="outlined"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.last_name}
                                    name="last_name"
                                    helperText={formik.touched.last_name && formik.errors.last_name}
                                    error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                                    fullWidth
                                    required
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    type="email"
                                    label="Email"
                                    variant="outlined"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    name="email"
                                    helperText={formik.touched.email && formik.errors.email}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Gender"
                                    variant="outlined"
                                    name='gender'
                                    fullWidth
                                    select
                                    onBlur={formik.handleBlur}
                                    value={formik.values.gender}
                                    onChange={formik.handleChange}
                                    helperText={formik.touched.gender && formik.errors.gender}
                                    error={formik.touched.gender && Boolean(formik.errors.gender)}
                                    required
                                >
                                    <MenuItem value="" disabled>Select your gender</MenuItem>
                                    <MenuItem value="male">Male</MenuItem>
                                    <MenuItem value="female">Female</MenuItem>
                                    <MenuItem value="unspecified">Unspecified</MenuItem>
                                    <MenuItem value="undisclosed">Undisclosed</MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    type="password"
                                    label="Password"
                                    variant="outlined"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password}
                                    name="password"
                                    helperText={formik.touched.password && formik.errors.password}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    htmlFor="Password"
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    type="password"
                                    label="Confirm Password"
                                    variant="outlined"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.confirmPassword}
                                    name="confirmPassword"
                                    htmlFor="confirmPassword"
                                    helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                    error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                    fullWidth
                                    required
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                sx={{
                                    '&:hover': {
                                        backgroundColor: '#FBAD18',
                                        color: 'white',
                                    }, backgroundColor: '#1E1A54',
                                    textTransform: 'none'
                                }}
                                fullWidth
                                onClick={() => formik.handleSubmit()}
                            >
                                Create an account
                            </Button>
                        </Grid>
                        <Grid item container xs={12} justifyContent={'center'}>
                            <Typography color='#1E1A54' variant="body2">
                                Already have an account? <Link to="/login_page">Log In</Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                )
            }
            }
        </FormHandler>
    )
}

export default Sign_upPage;
