import { Avatar, Button, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import FormHandler from '../shared-components/FormHandler';
import * as yup from 'yup'
import { useNavigate, useLocation } from "react-router-dom"
import { useDispatch } from 'react-redux';
import { handleOpenSnackbar } from '../../store/homeSlice';
import { checkPasswordUniqueness } from '../constants/navbarConstants';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { resetPassword } from '../../store/userSlice';
function ResetPassword() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const tokenFromQuery = searchParams.get('token');
    console.log("🚀 ~ ResetPassword ~ tokenFromQuery:", tokenFromQuery)
    const initialValues = {
        password: '',
        confirmPassword: ''
    }
    const validationSchema = yup.object({
        password: yup.string()
            .required('Required')
            .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                'Password must be strong'
            )
            .test('unique-password', 'Password must be unique', async function (value) {
                const isUnique = await checkPasswordUniqueness(value);
                return isUnique;
            }),
        confirmPassword: yup.string()
            .oneOf([yup.ref('password'), null], 'Passwords must match')
            .required('Required'),
    })
    const handleResetPassword = ({ values, actions }) => {
        const payload = {
            password: values.password,
            token: tokenFromQuery
        }
        const formData = new FormData()
        Object.entries(payload).forEach(([key, value]) => {
            formData.append(key, value);
        });
        dispatch(resetPassword(formData)).then((response) => {
            if (response?.error) {
                dispatch(handleOpenSnackbar({
                    open: true,
                    type: 'error',
                    message: 'Something went wrong!'
                }))
            } else {
                dispatch(handleOpenSnackbar({
                    open: true,
                    type: 'success',
                    message: 'Password Reset Succesfully!'
                }))
                navigate('/login_page')
            }
        })
    }
    return (
        <FormHandler
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleResetPassword}
            isEditMode={false}
            hideActionButton={true}
        >
            {({ formik }) => {
                return (
                    <Grid container sx={{ backgroundImage: `url('images/image 19.png')`, backgroundSize: 'cover', minHeight: '92.8dvh', paddingLeft: '10%', paddingTop: '10%' }}>
                        <Grid container spacing={2} lg={4} md={5} sm={8} xs={10} sx={{ backgroundColor: 'white', height: '500px', boxShadow: '0 0 10px 0 gray', color: 'black', padding: '20px 20px', borderRadius: '10px' }}>
                            <Grid item xs={12} container spacing={1}>
                                <Grid item xs={12} container justifyContent={'center'}>
                                    <Typography fontWeight={700} color='#1E1A54' fontSize={25}>Reset Password!</Typography>
                                </Grid>
                                <Grid item xs={12} justifyContent={'center'}>
                                    <Typography fontSize={16} sx={{ textAlign: 'center' }} color='#1E1A54'>Set The new password for your Account So you can login and Access all the features.</Typography>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        type="password"
                                        label="New Password"
                                        variant="outlined"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.password}
                                        name="password"
                                        helperText={formik.touched.password && formik.errors.password}
                                        error={formik.touched.password && Boolean(formik.errors.password)}
                                        htmlFor="Password"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        type="password"
                                        label="Confirm Password"
                                        variant="outlined"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.confirmPassword}
                                        name="confirmPassword"
                                        htmlFor="confirmPassword"
                                        helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                        error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: '#FBAD18',
                                            color: 'white',
                                        }, backgroundColor: '#1E1A54'
                                    }}
                                    fullWidth
                                    onClick={() => formik.handleSubmit()}
                                >
                                    Sign In
                                </Button>
                            </Grid>
                            <Grid item xs={12} container justifyContent={'center'} padding={'20px'}>
                                <Typography color={'#49475A'} sx={{ cursor: 'pointer' }} display={'flex'} gap={1} onClick={() => navigate('/login_page')}>
                                    <ArrowBackIcon /> Back to login
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }
            }
        </FormHandler>
    )
}

export default ResetPassword;
