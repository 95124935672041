import { Avatar, Button, Grid, Typography } from '@mui/material'
import React from 'react'

const Section4 = () => {
    return (
        <Grid container justifyContent={'space-between'} item sx={{ padding: { lg: '100px 200px', sm: '60px 100px', xs: '50px 50px' } }} xs={12} spacing={2}>
            <Grid item xs={6} container rowGap={1}>
                <Typography sx={{ lineHeight: '67.2px' }} fontSize={56} fontWeight={600} color='white'>Discover Your Dream Destinations Today</Typography>
                <Button sx={{
                    '&:hover': {
                        backgroundColor: '#1E1A54',
                        color: 'white',
                    },
                    backgroundColor: '#1E1A54',
                    color: 'white',
                    textTransform: 'none',
                    width: '214px',
                    height: '56px',
                    borderRadius: '40px',
                    padding: '18px 32px',
                    fontSize: '16px'
                }}>
                    Discover the World
                </Button>
            </Grid>
            <Grid item>
                <Avatar maxWidth src={'section4/BG image.svg'} sx={{ borderRadius: 0, objectFit: 'unset', width: {lg : '291px'}, height: {lg : '263px'} }} />
            </Grid>
        </Grid>
    )
}

export default Section4